import _objectSpread from "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import { mapGetters, mapMutations } from "vuex";
import AsideComponent from "@/view/layout/aside/asideComponent";
export default {
  name: "Aside",
  data: function data() {
    return {
      active: "",
      isCollapse: true
    };
  },
  methods: _objectSpread(_objectSpread({}, mapMutations("history", ["addHistory"])), {}, {
    selectMenuItem: function selectMenuItem(index, _, ele) {
      var query = {};
      var params = {};
      ele.route.parameters && ele.route.parameters.map(function (item) {
        if (item.type == "query") {
          query[item.key] = item.value;
        } else {
          params[item.key] = item.value;
        }
      });
      if (index === this.$route.name) return;
      if (index.indexOf("http://") > -1 || index.indexOf("https://") > -1) {
        window.open(index);
      } else {
        this.$router.push({
          name: index,
          query: query,
          params: params
        });
      }
    }
  }),
  computed: _objectSpread({}, mapGetters("router", ["asyncRouters"])),
  components: {
    AsideComponent: AsideComponent
  },
  created: function created() {
    var _this = this;
    this.active = this.$route.name;
    // let screenWidth = document.body.clientWidth;
    // if (screenWidth < 1000) {
    //   this.isCollapse = !this.isCollapse;
    // }

    this.$bus.on("collapse", function (item) {
      _this.isCollapse = item;
    });
  },
  watch: {
    $route: function $route() {
      this.active = this.$route.name;
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$bus.off("collapse");
  }
};